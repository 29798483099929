import React from 'react'
import "./Solutions.css";
import { IdunnuPro, p1, p2, p3, p4, PowerGenerator } from '../../Assets';
import { Carousel } from 'react-responsive-carousel';

const Solutions = () => {
  return (
    <div className='content__padding quadloop__solutions' id='solutions'>
      <h2>Our Solutions</h2>

      <div className='quadloop__solutions_images'>

        <div className='quadloop__solutions_lamp'>
        <Carousel autoPlay={true} showArrows={false} infiniteLoop={true} showStatus={false} showThumbs={false}>
          <img src={p1} alt="About Us 1" />
          <img src={p2} alt="About Us 2" />
          <img src={p3} alt="About Us 3" />
          <img src={p4} alt="About Us 4" />
        </Carousel>
        </div>

        <div className='quadloop__solutions_others'>        
          <a href="#0" ><img src={IdunnuPro} alt="Idunnu PRO" /></a>
          <a href="#0" ><img src={PowerGenerator} alt="Power Generator" /></a>
        </div>
        
      </div>
    </div>
  )
}

export default Solutions