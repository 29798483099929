import React from 'react'
import "./Partners.css"
import { fate, nci, hinckley, ifair, Footprint, Mass, Startup, nextexplo, wef, qualcomm, cv } from '../../Assets'

const Partners = () => {
  return (
    <div className='content__padding quadloop__partners' id='partners'>
      <h2> Affiliations</h2>
      <div className='quadloop__partners_images'>
        <a href="#0" ><img src={hinckley} alt="Hinckley Group" /></a>
        <a href="#0" ><img src={fate} alt="FATE Foundation" /></a>
        <a href="#0" ><img src={qualcomm} alt="qualcomm" /></a>
        <a href="#0" ><img src={Footprint} alt="Footprints Africa" /></a>
        <a href="#0" ><img src={Mass} alt="Mass Challenge" /></a>
        <a href="#0" ><img src={nci} alt="Nigeria Climate Innovation Centre" /></a>
        <a href="#0" ><img src={ifair} alt="i-fair" /></a>
        <a href="#0" ><img src={Startup} alt="Startup Bootcamp" /></a>
        <a href="#0" ><img src={nextexplo} alt="netexplo" /></a>
        <a href="#0" ><img src={wef} alt="wef" /></a>
        <a href="#0" ><img src={cv} alt="cv" /></a>


      </div>
    </div>
  )
}

export default Partners